import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode, useEffect } from 'react';
import type { NextPage } from 'next';
import CustomThemeProvider from '@context/themeContext';
import Head from 'next/head';

import '@fontsource/montserrat';
import '@fontsource/inter';
import '@fontsource/abeezee';
import '@fontsource/dm-sans';
import WarnMeModule from '@modules/WarnMe';
import LayoutWarnMe from '@layout/WarnMePage';

import * as gtag from '@lib/gtag'
import Analytics from '@components/GoogleAnalytics'
import { useRouter } from 'next/router';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement,pageProps?:any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  showWarnMe: boolean;
};

function App(props: AppPropsWithLayout) {
  const { Component, pageProps: initialPageProps, showWarnMe } = props;
  const { session, ...pageProps } = initialPageProps as any ?? {} as any;

  const getLayout = showWarnMe
    ? (page: any) => <LayoutWarnMe>{page}</LayoutWarnMe>
    : Component.getLayout ?? ((page) => page);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (showWarnMe) {
    return (
      <CustomThemeProvider>
        {getLayout(
          <>
            <Head>
              {/* <link
                sizes="32x32"
                rel="shortcut icon"
                href="/imgs/favicon.ico"
              /> */}
              <link rel="icon" href="/imgs/favicon.ico" />
              <title>Promoção {process.env.NEXT_PUBLIC_APP_NAME} bem-estar premiado</title>
              <meta
                property="og:title"
                content={`Promoção ${process.env.NEXT_PUBLIC_APP_NAME} bem-estar premiado`}
                key="title"
              />
            </Head>
            <WarnMeModule />
            <Analytics />
          </>
        )}
      </CustomThemeProvider>
    );
  }

  return (
    <SessionProvider session={session}>
      <CustomThemeProvider>
        {getLayout(
          <>
            {/* <ModalComponent/> */}
            <Head>
              <link
                sizes="32x32"
                rel="shortcut icon"
                href="/imgs/favicon.ico"
              />
              <title>Promoção {process.env.NEXT_PUBLIC_APP_NAME}</title>
              <meta name="viewport" content="width=device-width, user-scalable=no" />
              <meta
                property="og:title"
                content={`Promoção ${process.env.NEXT_PUBLIC_APP_NAME}`}
                key="title"
              />
            </Head>
            <Component {...pageProps} />
            <Analytics />
          </>
        ,pageProps)}
      </CustomThemeProvider>
    </SessionProvider>
  );
}
App.getInitialProps = async () => {
  // Use the layout defined at the page level, if available
  // const ENV = process.env.NEXT_PUBLIC_NODE_ENV;
  const SHOW_NEWSLETTER = process.env.SHOW_NEWSLETTER;
  let showWarnMe = false;
  if (SHOW_NEWSLETTER == 'true') {
    showWarnMe = true;
  }
  return { showWarnMe };
};

export default App;
