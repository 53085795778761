import { PhonePin } from '@interfaces/Phone';
import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';

/**           POST SEND PIN INIT     */

interface sendPinByUserIdReqProps {
  userId: number;
  phone: string;
}

export const sendPinByUserIdReq = async ({
  userId,
  phone,
}: sendPinByUserIdReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(`/api/users/${userId}/pin`, {
      body: JSON.stringify({ msisdn: phone }),
    });

    const sendPinByUserIdRes = response.data;
    logger.debug('sendPinByUserIdRes:', sendPinByUserIdRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return undefined;
    }
    return sendPinByUserIdRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           POST SEND PIN END     */

/**           GET CHECK PIN INIT     */

interface checkPinByUserIdReqProps {
  userId: number;
  pin: string;
}

export const checkPinByUserIdReq = async ({
  userId,
  pin,
}: checkPinByUserIdReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(`/api/users/${userId}/checkpin`, {
      body: JSON.stringify({ code: pin }),
    });

    const checkPinByUserIdRes = response.data;
    logger.debug('checkPinByUserIdRes:', checkPinByUserIdRes);

    if (response.status !== 200) {
      logger.error('Erro ao validar pin, por favor tente novamente...');
      return undefined;
    }
    return checkPinByUserIdRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           GET CHECK PIN END     */

/**           PUT USER PHONE INIT     */

interface updateUserPhoneReqProps {
  userId: number;
  phone: string;
}

export const updateUserPhoneReq = async ({
  userId,
  phone,
}: updateUserPhoneReqProps): Promise<PhonePin | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.put(`/api/auth/put-user-by-id?id=${userId}`, {
      body: JSON.stringify({ phone }),
    });

    const updateUserPhoneRes = response.data;
    logger.debug('updateUserPhoneRes:', updateUserPhoneRes);
    if (response.status !== 200) {
      logger.error('Erro ao salvar o telefone, por favor tente novamente...');
      return undefined;
    }
    return updateUserPhoneRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           PUT USER PHONE END     */

export const sendWarnMeReq = async ({
  email
}: {email:string}): Promise<{message:string,timestamp:string} | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.post(`/api/users/warnme`, {
      email,
    });

    const sendWarnMeRes = response.data;
    logger.debug('sendWarnMeRes:', sendWarnMeRes);
    if (response.status !== 201) {
      logger.error('Erro ao enviar o email, por favor tente novamente...');
      return undefined;
    }
    return sendWarnMeRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetcheckPinByUserIdReq:" + error);
    return undefined;
  }
};

/**           PUT USER PHONE END     */
